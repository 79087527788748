import Helper from 'app/modules/Helper';
import Image from 'next/image';
import React from 'react';
import { Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import Button from '../../Common/Button';
import PhoneIcon from '../../Common/Icons/PhoneIcon';
import PhoneIconRounded from '../../Common/PhoneIconRounded';

interface HeaderProps {
	uid?: string;
	imid?: string;
	lng: string;
	userCountry: string;
}
interface HeaderState {
	isMobile: boolean;
	phoneNumber: string;
	tooltipText: string;
}
class Header extends React.PureComponent<HeaderProps, HeaderState> {
	constructor(props: HeaderProps) {
		super(props);
		this.state = {
			isMobile: false,
			phoneNumber: '650 513-8248',
			tooltipText: 'Click here to copy',
		};
		if (typeof window != 'undefined') {
			window.addEventListener('resize', this.checkWindowSize);
		}
	}
	checkWindowSize = () => {
		const width = window.innerWidth;
		this.setState({
			isMobile: width < 575,
		});
	};

	componentDidMount() {
		if (typeof window != 'undefined') this.checkWindowSize();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.checkWindowSize);
	}

	copyPhoneNumber = () => {
		setTimeout(async () => {
			navigator.clipboard.writeText(this.state.phoneNumber);
			this.setState({
				tooltipText: 'Copied!',
			});
		}, 100);

		setTimeout(() => {
			this.setState({
				tooltipText: 'Click here to copy',
			});
		}, 3000);
	};
	render() {
		const allowedCountries = ['US', 'CA'];
		const { isMobile, tooltipText } = this.state;
		const { uid, imid, lng, userCountry } = this.props;
		const showPhone = (allowedCountries.includes(userCountry) && lng === 'en') || lng === 'es';
		const phoneNumber = lng === 'es' ? '858-633-8912' : '650 513-8248';
		return (
			<div>
				<Navbar expand="lg">
					<div className="container">
						<div
							className="row  justify-content-between align-items-center"
							style={{ width: '100%', margin: '0 auto' }}
						>
							<div className="col-md-6 col-6 text-md-start text-center">
								<Navbar.Brand href="/">
									<Image
										width={isMobile ? 190 : 260}
										height={isMobile ? 31 : 40}
										alt={process.env.NEXT_PUBLIC_SITE_NAME as string}
										src="/images/logos/new-logo.png?v12.1"
										className="Logo"
										priority={true}
										style={{ objectFit: 'contain', height: 'auto' }}
									/>
								</Navbar.Brand>
							</div>
							{showPhone && (
								<div className="col-md-6 col-6 d-flex justify-content-end">
									{isMobile ? (
										<div className="phone-number-mobile">
											<Button
												isLink={true}
												className=" call-button call-button-mobile"
												id="smarterflyer_top_phone_button"
												imid={imid}
												uid={uid}
												href={`tel:${phoneNumber}`}
											>
												<div className="text">{Helper.trans('phoneSection.callNow')}</div>
												<PhoneIconRounded className="phone-icon-rounded" width={20} height={20} />
												<div className="content-bottom">
													Powered by <span className="powered-by">{Helper.trans('phoneSection.partnerPoweredBy')}</span>
												</div>
											</Button>
										</div>
									) : (
										<div className="phone-number-destkop">
											<div className="text-top">{Helper.trans('phoneSection.callNow')}</div>
											<div className="phone-number">
												<PhoneIcon className="phone-icon" width={18} height={18} color="#fff" />
												<div data-for="phone-tooltip" className="phone-number" onClick={this.copyPhoneNumber}>
													{phoneNumber}
												</div>
												<Tooltip id="phone-tooltip">{tooltipText}</Tooltip>
											</div>
											<div className="text-bottom">
												Powered by <span className="powered-by">{Helper.trans('phoneSection.partnerPoweredBy')}</span>
											</div>
										</div>
									)}
								</div>
							)}
						</div>
					</div>
				</Navbar>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		lng: state.base.lng,
		uid: state.base.uid,
		imid: state.base.imid,
		userCountry: state.base.countryCode,
	};
};

export default connect(mapStateToProps, {})(Header);
