import { omit } from 'lodash';
import { FC, useEffect, useRef } from 'react';

interface ButtonProps {
	isLink?: boolean;
	children?: React.ReactNode;
	className: string;
	title?: string;
	id?: string;
	href?: string;
	uid?: string;
	imid?: string;
	onClick?: () => void;
}

const Button: FC<ButtonProps> = (props) => {
	const { isLink, children, id, uid, imid } = props;

	const buttonRef = useRef<HTMLButtonElement>(null);
	const linkRef = useRef<HTMLAnchorElement>(null);

	const handleClick = () => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'buttonClick',
			buttonName: id,
			userId: `${uid}${imid}`,
		});
	};

	useEffect(() => {
		const element = isLink ? linkRef.current : buttonRef.current;
		element?.addEventListener('click', handleClick);

		return () => {
			element?.removeEventListener('click', handleClick);
		};
	}, []);

	const propsWithoutIsLink = omit(props, 'isLink');

	return isLink ? (
		<a ref={linkRef} {...propsWithoutIsLink}>
			{children}
		</a>
	) : (
		<button ref={buttonRef} {...propsWithoutIsLink}>
			{children}
		</button>
	);
};

export default Button;
