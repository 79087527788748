import { handleSigninCheckbox } from 'actions/baseActions';
import Helper from 'app/modules/Helper';
import { FC, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';

interface SigninCheckboxProps {
	isChecked: boolean;
	handleSigninCheckbox: (isChecked: boolean) => void;
}

const SigninCheckbox: FC<SigninCheckboxProps> = (props) => {
	const { isChecked, handleSigninCheckbox } = props;
	const elRef = useRef<HTMLDivElement | null>(null);
	const [isMounted, setIsMounted] = useState(false);

	if (typeof window !== 'undefined' && !elRef.current) {
		elRef.current = document.createElement('div');
		elRef.current.classList.add('google-one-tap-desc', Helper.isMobile() ? 'mobile' : 'desktop');
	}

	useEffect(() => {
		const observer = new MutationObserver((mutationsList, observer) => {
			let portalRoot = document.getElementById('credential_picker_container');
			const iframeContainer = document.getElementById('credential_picker_iframe');

			for (const mutation of mutationsList) {
				if (mutation.addedNodes[0] === portalRoot || mutation.addedNodes[0] === iframeContainer) {
					if (Helper.isMobile() && iframeContainer) {
						portalRoot = document.body;
					}

					if (portalRoot && elRef.current) {
						setTimeout(() => {
							elRef.current?.classList.remove('hide');
							elRef.current?.classList.add('show');

							portalRoot?.appendChild(elRef.current as HTMLDivElement);
							setIsMounted(true);
						}, 100);

						return () => {
							portalRoot?.removeChild(elRef.current as HTMLDivElement);
						};
					}
				} else if (mutation.removedNodes.length) {
					const isMobile = Helper.isMobile();

					const authWrapper = isMobile
						? document.getElementById('credential_picker_iframe')
						: document.getElementById('credential_picker_container');

					if (!authWrapper && elRef.current) {
						elRef.current.remove();
					}
				}
			}
		});

		observer.observe(document, { childList: true, subtree: true });

		return () => observer.disconnect();
	}, []);

	return isMounted && elRef.current
		? createPortal(
				<>
					<h4>Sign in to unlock the best flight deals</h4>
					<div className="disclaimer-checkbox">
						<Form.Check
							label="Send me special deals from Smarter Flyer"
							checked={isChecked}
							onChange={() => handleSigninCheckbox(!isChecked)}
						/>
					</div>
				</>,
				elRef.current
		  )
		: null;
};

const mapStateToProps = (state: any) => ({
	isChecked: state.base.signinCheckbox,
});

export default connect(mapStateToProps, { handleSigninCheckbox })(SigninCheckbox);
