import { FC } from 'react';

interface PhoneIconRoundedProps {
	className?: string;
	width: number;
	height: number;
	color?: string;
}
const PhoneIconRounded: FC<PhoneIconRoundedProps> = (props) => {
	const { className, width = 20, height = 20, color = '#fff' } = props;

	return (
		<svg fill={color} width={width} height={height} className={className} viewBox="0 0 20 20">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM12.5345 10.7586L14.8163 12.8333C14.9245 12.9314 14.99 13.0679 14.999 13.2137C15.0079 13.3595 14.9594 13.503 14.864 13.6136L13.8199 14.8322C13.723 14.9297 13.5934 14.9879 13.4562 14.9956C13.4376 14.9967 13.368 15.0001 13.2546 15.0001C12.4789 15.0001 9.66208 14.8356 7.41335 12.5874C4.83569 10.0087 4.99623 6.68441 5.00465 6.54407C5.01246 6.4068 5.07039 6.27716 5.16744 6.17976L6.38554 5.13511C6.49646 5.04005 6.64007 4.99198 6.78586 5.0011C6.93166 5.01021 7.06816 5.07581 7.16637 5.18395L9.24051 7.46523C9.33868 7.57303 9.39106 7.7148 9.38655 7.86053C9.38205 8.00626 9.32102 8.14452 9.21638 8.24605L7.83604 9.5899C7.89611 9.91098 8.09875 10.5627 8.76787 11.2324C9.43698 11.8998 10.087 12.1025 10.4103 12.1642L11.7536 10.7827C11.8552 10.6782 11.9935 10.6173 12.1392 10.6128C12.2848 10.6083 12.4266 10.6606 12.5345 10.7586Z"
				fill="black"
			/>
		</svg>
	);
};
export default PhoneIconRounded;
