import GoogleSignin from 'app/components/Common/GoogleSignin';
import Helper from 'app/modules/Helper';
import dynamic from 'next/dynamic';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { openChildInput, openInput, togglePreventEnter, togglePreventFocus } from '../../../actions/baseActions';
import { doSearchFlights } from '../../../actions/flightActions';
// import Footer from './MainLayout/Footer';
import Header from './MainLayout/Header';

const ProgressLoading = dynamic(() => import('../Common/ProgressLoading'), { ssr: false });
const BodyHighlight = dynamic(() => import('../Common/BodyHighlight'), { ssr: false });
const CookieNotice = dynamic(() => import('../Common/CookieNotice'));
const Footer = dynamic(() => import('./MainLayout/Footer'));

// const useDefaultPasstrhu = Helper.checkQueryStringKeyValue('f', 'm');

type InputName = {
	inputs: string[];
	childInputs: Record<string, string>;
};
const inputNames: Record<string, InputName> = {
	flights: {
		inputs: ['airport-from', 'airport-to', 'date-from', 'date-to'],
		childInputs: {},
	},
};

interface MainLayoutProps {
	children: React.ReactNode;
}

const MainLayout: FC<MainLayoutProps & MainLayoutReduxProps> = (props) => {
	const [showGoogleOneTap, setShowGoogleOneTap] = useState(false);

	const onTabPressed = (e: KeyboardEvent) => {
		if (props.preventEnter) {
			props.togglePreventEnter(false);
			return true;
		}
		props.togglePreventFocus(false);

		const INPUTS = inputNames[props.enabledVert].inputs;
		const CHILD_INPUTS = inputNames[props.enabledVert].childInputs;
		let openedInput;

		const openedInputId = document.activeElement?.id;

		if (document.hasFocus()) {
			openedInput = openedInputId?.replace('-input', '');
		}

		if (!openedInput) {
			openedInput = props.openedInput;
		}

		switch (e.key) {
			// Tab
			case 'Tab':
				if (INPUTS.indexOf(openedInput) !== -1) {
					if (CHILD_INPUTS[openedInput]) {
						props.openChildInput(CHILD_INPUTS[openedInput]);
					}
					props.openInput(openedInput);
				}
				if (INPUTS.indexOf(openedInput) === -1 && openedInputId !== '') {
					props.openInput(null);
					props.openChildInput(null);
				}
				break;
			// Esc
			case 'Escape':
				props.openInput(null);
				props.openChildInput(null);
				break;
			// Enter
			case 'Enter':
				if (
					(INPUTS.indexOf(openedInput) !== -1 || openedInputId === '') &&
					['date-from', 'date-to'].indexOf(openedInput) === -1
				) {
					switch (props.enabledVert) {
						case 'flights':
							props.doSearchFlights();
							break;
						default:
							break;
					}
				}
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		window.addEventListener('keyup', onTabPressed);

		return () => {
			window.removeEventListener('keyup', onTabPressed);
		};
	}, []);

	useEffect(() => {
		const userCountry = props.countryCode;

		if (userCountry) {
			const isFromEU = Helper.isFromEU(userCountry);

			if (isFromEU) {
				return;
			} else {
				setShowGoogleOneTap(true);
			}
		}
	}, [props.countryCode]);

	return (
		<div>
			<ProgressLoading partner={props.partner} />
			{showGoogleOneTap && <GoogleSignin />}
			<div
				style={{
					display: props.enableProgressLoading ? 'none' : 'block',
				}}
			>
				<BodyHighlight />
				<CookieNotice />
				<Header />
				{props.children}
				<Footer />
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => {
	const { enabledVert, openedInput, preventEnter, enableProgressLoading, countryCode } = state.base;
	const { partner } = state.passthru;

	return {
		enabledVert,
		openedInput,
		preventEnter,
		enableProgressLoading,
		partner,
		countryCode,
	};
};

const mapDispatchToProps = { openInput, openChildInput, doSearchFlights, togglePreventEnter, togglePreventFocus };

type MainLayoutReduxProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
