import { setPriceAlertEmail, userSignIn } from 'actions/baseActions';
import SigninCheckbox from 'app/components/Common/SigninCheckbox';
import ApiInterface from 'app/modules/ApiInterface';
import Helper from 'app/modules/Helper';
import dynamic from 'next/dynamic';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';

interface GoogleOneTapOptions {
	client_id: string;
	auto_select?: boolean;
	cancel_on_tap_outside?: boolean;
	context?: string;
	[key: string]: any;
}

declare global {
	interface Window {
		google: any;
	}
}

const googleOneTap = (
	{
		client_id,
		auto_select = false,
		cancel_on_tap_outside = false,
		context = 'signin',
		...otherOptions
	}: GoogleOneTapOptions,
	callback: (response: any) => void
) => {
	if (!client_id) {
		throw new Error('client_id is required');
	}

	if (typeof window !== 'undefined' && window.document) {
		const contextValue = ['signin', 'signup', 'use'].includes(context) ? context : 'signin';

		// Check if the script is already added
		if (!document.querySelector('script[src="https://accounts.google.com/gsi/client"]')) {
			const googleScript = document.createElement('script');
			googleScript.src = 'https://accounts.google.com/gsi/client';
			googleScript.async = true;
			googleScript.defer = true;
			document.head.appendChild(googleScript);

			window.addEventListener('load', () => {
				if (window?.google?.accounts?.id) {
					window.google.accounts.id.initialize({
						client_id,
						callback,
						auto_select,
						cancel_on_tap_outside,
						context: contextValue,
						...otherOptions,
					});
					window.google.accounts.id.prompt();
				}
			});
		}
	}
};

interface GoogleSigninProps {
	initialize?: boolean;
}

const GoogleSignin: FC<GoogleSigninProps> = (props) => {
	const dispatch = useDispatch();
	const store = useStore();
	const [showCheckbox, setShowCheckbox] = useState(false);

	const onGoogleSignIn = (data: { email: string }) => {
		const { email } = data;
		const isSigninCheckboxChecked = store.getState().base.signinCheckbox;

		if (isSigninCheckboxChecked) {
			setPriceAlertEmail(email)(dispatch);

			const params = new URLSearchParams();
			params.append('user-id', email);
			params.append('lng', document.documentElement.lang);

			ApiInterface.instance
				.newsletterSubscription('hotels', params)
				.then(() => {})
				.catch((err: any) => {
					console.log(err);
				});
		}
		userSignIn(true)(dispatch);
	};

	const signinOptions = {
		client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string,
		auto_select: false,
		cancel_on_tap_outside: false,
		context: 'signin',
	};

	useEffect(() => {
		setTimeout(() => {
			setShowCheckbox(true);
		}, 600);
	}, []);

	const handleCredentialResponse = (response: any) => {
		const data = Helper.parseJwt(response.credential);
		onGoogleSignIn(data);
	};

	return (
		<>
			{showCheckbox && <SigninCheckbox />}
			{googleOneTap(signinOptions, handleCredentialResponse)}
		</>
	);
};

export default dynamic(() => Promise.resolve(GoogleSignin), { ssr: false });
